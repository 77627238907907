import styled from '@emotion/styled';
import React from 'react';
import useFormContextHook from '../../../hooks/useFormContextHook';
import SendIcon from '@mui/icons-material/Send';
import { usePostLottoQuestion } from '../../../api/fetchPostLottoQuestion';
import { storage } from '../../../hooks/useStorage';
import { v4 as uuidv4 } from 'uuid';
import { DeviceUUID } from 'device-uuid';
import CircularProgress from '../../common/CircularProgress';
import useSendNativeEventBridge from '../../../hooks/useSendNativeEventBridge';

const AiQuestionInput = () => {
  const { watch, setValue, register } = useFormContextHook();
  const talks = watch('talks');
  const question = watch('question') ?? '';
  const { sendToNative } = useSendNativeEventBridge();
  const { mutate, isLoading } = usePostLottoQuestion();

  const uuid = new DeviceUUID().get() || uuidv4();

  const askQuestionToAI = async () => {
    // const recieveValue: any = await receive(push('AskQuestionBottomSheet', {}));
    if (!question || question?.length < 1) {
      alert('질문을 입력해주세요');
      return;
    }
    if (question?.length > 30) {
      alert('질문은 30자 이내로 입력해주세요. 장문의 질문은 추후 업데이트 예정입니다.');
      return;
    }
    if (isLoading) {
      alert('AI가 답변중입니다. 잠시만 기다려주세요');
      return;
    }

    sendToNative('showInterstitialAd', {});

    setValue('talks', [
      ...talks,
      {
        author: 'USER',
        message: question,
      },
    ]);
    setValue('question', '');

    await mutate(
      {
        deviceId: uuid,
        question: question,
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            const newTalks = storage('talks').get();
            setValue('talks', [
              ...newTalks,
              {
                author: 'AI',
                message: data.data,
                createdAt: new Date(),
              },
            ]);
            setValue('typing', true);
          } else {
            const newTalks = storage('talks').get();
            setValue('talks', [
              ...newTalks,
              {
                author: 'AI',
                message:
                  '죄송합니다. 일시적 에러가 발생해 답변을 드리지 못했습니다. 다시 시도해주세요',
                createdAt: new Date(),
              },
            ]);
          }
        },
        onError: (error) => {
          console.log(error);
          const newTalks = storage('talks').get();
          setValue('talks', [
            ...newTalks,
            {
              author: 'AI',
              message:
                '죄송합니다. 일시적 에러가 발생해 답변을 드리지 못했습니다. 다시 시도해주세요',
              createdAt: new Date(),
            },
          ]);
          setValue('typing', true);
        },
      }
    );
  };

  return (
    <InputWrapper>
      <Input
        placeholder="질문을 입력해주세요..."
        maxLength={30}
        {...register('question')}
        disabled={isLoading}
      />
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <SendIcon sx={{ color: 'white', transform: 'rotate(-45deg)' }} onClick={askQuestionToAI} />
      )}
    </InputWrapper>
  );
};

export default AiQuestionInput;

const InputWrapper = styled.div`
  width: 100%;
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #9e9e9e;
  background-color: transparent;
  color: white;

  &:focus {
    outline: none;
    border: 2px solid #ffffff;
  }
`;
